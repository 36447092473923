<script setup>
import { useCategoriesStore } from "../../stores/search";
import { useRoute, useRouter } from "vue-router";
import { useLabel } from "../../composables/useLabel";

const props = defineProps({
  products: Object,
  translations: Object
});

useLabel().set('PRODUCT_CATEGORY', props.categoryLabel);

useCategoriesStore().setCategories({
    products: props.products.allProducts,
    categories: props.products.categoriesWithProducts,
    translations: props.translations
});

useRouter().beforeResolve((to, from) => {
  let toQuery = structuredClone(to.query);
  if(toQuery.page){
    delete toQuery.page 
  }
  let fromQuery = structuredClone(from.query);
  if(fromQuery.page){
    delete fromQuery.page 
  }
});
</script>

<template>
  <bread-crumb></bread-crumb>
  <router-view />
</template>